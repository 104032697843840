<template>
  <Dropdown v-if="user" position="right" :ui="{ items: 'w-80 right-auto -translate-x-1/2 md:right-0 md:translate-x-0' }" class="mt-2 md:mt-0">
    <template #label>
      <div class="mr-1.5 flex items-center space-x-4">
        <div class="flex items-center space-x-2.5">
          <Icon icon="sparkles" prefix="far" />
          <span class="hidden md:block"
            >{{ $t("complete_your_profile") }} <span class="text-sm text-supplement">({{ user.profile_completion_percentage }}%)</span></span
          >
        </div>
        <div class="relative h-2 w-10 overflow-hidden rounded-full">
          <div class="absolute inset-0 bg-gray-200" />
          <div class="absolute inset-y-0 left-0 bg-pink-600" :style="{ width: `${user.profile_completion_percentage}%` }" />
        </div>
      </div>
    </template>

    <div class="p-6">
      <div class="space-y-1.5">
        <div v-for="task in tasks" class="flex items-center space-x-3">
          <div class="grid size-4 shrink-0 place-items-center">
            <Icon v-if="user.profile_tasks[task]" icon="check" class="text-green-600" />
            <div v-else class="size-3.5 rounded border border-input" />
          </div>
          <div v-if="task === 'iata_clia'">IATA / CLIA</div>
          <div v-else>{{ $t(task) }}</div>
        </div>
      </div>
      <p class="mt-6">
        <Button to="/profile" full-width prepend-icon="pencil" size="sm">{{ $t("update_profile") }}</Button>
      </p>
    </div>
  </Dropdown>
</template>

<script setup lang="ts">
const user = useUser();

const tasks = ["name", "email", "logo_personal_photo", "location", "host_agency", "consortium", "booking_email_phone", "iata_clia", "company_name", "language"];
</script>
